import React, {useState} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/global.scss"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";


const Events = (events) => {
  if (events.data.length > 0) {
    const html = events.data.map(event => (
      <div key={`event-${event.id}`} className="event">
        <h2 className="title">{event.name}</h2>
        <p className="subtitle">{event.description}</p>
        <p className="event__data"><span className="event__label">Start Date</span>{event.start_date}</p>
        <p className="event__data"><span className="event__label">End Date</span>{event.end_date}</p>
        <p className="event__data"><span className="event__label">Location</span>{event.location}</p>
      </div>
    ))
    return html
  } else {
    return (
      <div>There are no events for the dates selected.</div>
    )
  }
}

export default ({data}) => {
  const {
    pageData: {
      calendar
    }
  } = data
  
  const getFilteredData = () => {
    const filteredData = []
    calendar.forEach(event => {
      let eventStart = new Date(event.start_date)
      if (eventStart >= startDate && eventStart <= endDate) {
        filteredData.push(event)
      }
    })
    return filteredData
  }

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date().setFullYear(new Date().getFullYear() + 1))

  const handleChange = (date, which) => {
    switch (which) {
      case 'start':
        setStartDate(date)
        break
      case 'end':
        setEndDate(date)
        break
      default: 
    }
  }

  return (
    <Layout title="Calendar">
      <SEO title="Calendar" />
      <section>
        <div className="container">
          <div className="columns is-8 is-variable">
            <div className="column is-one-quarter">
              <aside className="menu" style={{position: `sticky`, top: `1em`}}>
                <div style={{marginBottom: `1em`}}>
                  <label htmlFor="firstname" style={{fontWeight: `bold`, display: `block`}}>Start Date:</label>
                  <DatePicker id="startdate" selected={startDate} onChange={date => handleChange(date, 'start')} />
                </div>
                <div>
                  <label htmlFor="enddate" style={{fontWeight: `bold`, display: `block`}}>End Date:</label>
                  <DatePicker id="enddate" selected={endDate} onChange={date => handleChange(date, 'end')} />
                </div>
              </aside>
            </div>
            <div className="column">
             <Events data={getFilteredData()}/>
            </div>
          </div>
        </div>
     </section>
  <style jsx="jsx">{`
    .event {
      padding-bottom: 2em;
      border-bottom: 1px solid #e1e1e1;
    }
    .event:not(:first-child) {
      padding-top: 2em;
    }
    .event__data {
      margin-bottom: 1em;
      font-size: 1.2em;
    }
    .event__label {
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      font-size: 0.7em;
      line-height: 0.7em;
    }
  `}
  </style>
    </Layout>
  )
}

export const query = graphql`
  query calendarQuery {
    pageData {
      calendar {
        id
        name
        start_date
        end_date
        location
        description
      }
    }
  }
`